import { EnvironmentModel } from 'src/environments/environment.model';

export const environment: EnvironmentModel = {
  site: 'https://impostograma.com.br',
  emailAtendimento: 'atendimento@impostocerto.com.br',
  emailDemo: 'demo@impostocerto.com.br',
  emailSustentacao: 'sustentacao@impostocerto.com.br',
  emailSustentacaoComercial: 'sustentacao.comercial@impostocerto.com.br',
  emailSuporte: 'sustentacao@impostocerto.com.br',
  emailTributario: 'tributario@impostocerto.com.br',
  production: true,
  URL: 'https://legacy.impostocerto.com.br/server/public/api/',
  baseURL: 'https://legacy.impostocerto.com.br/server/public/api/',
  proxy: 'https://legacy.impostocerto.com.br/server/public/',
  APICerto: 'https://api.impostocerto.com.br/ImpostoCerto/api/',
  API: 'https://api.impostocerto.com.br/ImpostoCerto/api/',
  Emalote: 'https://api.impostocerto.com.br/ICeMaloteWeb',
  AWSIntegrations: 'https://services.impostograma.com.br/integrations-prod',
  AWSImposto: 'https://services.impostograma.com.br/impostograma-prod',
  IGAuth: 'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
  Bucket: 'emalote',
  BucketTMP: 'tmp-process',
  AWSIa: 'https://services.impostograma.com.br/ia-prod',
  AWSUploads: 'https://services.impostograma.com.br/uploads-prod',
  calcularguia: 'calcular-guia',
  formularioPGDAS: 'formulario-pgdas',
  gaTag: '',
  gerenciarintegracoes: 'gerenciar-integracoes',
  modulos: {
    emalote: '1',
    auditoria: '2',
    revisao: '3',
    consulta: '4',
    relatorios: '7',
  },
  modulosTitle: {
    1: ' e-Malote Web',
    2: ' Auditoria',
    3: ' Revisão',
    4: ' Consulta Livre',
    7: ' Relatórios',
  },
  SuperLogicaToken: '',
  SuperlogicaDomain: '',
  pgdas: 'pgdas',
  nfstomadas: 'nfs-tomadas',
  SuperLogicaAccessToken: '',
  APP: 'impostocerto',
  APPSite: 'impostocerto',
  AWS: 'https://services.impostograma.com.br/',
  AWSAuth: 'RRwPrJsGdiwdWZ1CZj9srRtCdQ99LPeg',
  Ambiente: 'prod',
  BucketUpload: 'upload-ic-files',
  auditoria: 'auditoria',
  suporteauditoria: 'auditoria-suporte',
  contratarPacote: 'contratar-pacote',
  gerenciarprocessamentos: 'gerenciar-processamentos',
  Contabilidade: 'Gestor',
  contabilidade: 'gestor',
  aContabilidade: 'ao Gestor',
  umaContabilidade: 'um Gestor',
  daContabilidade: 'do Gestor',
  dasContabilidades: 'dos Gestores',
  plugincaptura: 'plugin-captura',
  nasContabilidades: 'no(s) gestores(s)',
  mensagemreativar: 'Esse Gestor já está cadastrado, deseja reativá-lo?',
  Contabilidadeatualizar:
    'Esse gestor já está cadastrado, deseja atualizá-lo ?',
  mensagemjacadastradoContabilidade:
    'Ops! Esse Gestor já está cadastrado em outra conta!',
  selecionarcontabilidade: 'selecionar gestor',
  SelecionarumaContabilidade: 'Selecione um Gestor!',
  continuarCadastrar: 'Para continuar você deve cadastrar um gestor',
  messagemdeletarusuario:
    'Por favor selecione o(s) gestor(es) em que você deseja deletar o usuário:',
  titleContabilidade: 'Complete o Cadastro do Gestor',
  MinhasContabilidades: 'Meus Gestores',
  rotaminhascontabilidades: 'meus-gestores',
  rotaadicionarcontabilidade: 'adicionar-gestor',
  rotausuariocontabilidade: 'usuario-gestor',
  ContabilidadeAlteradaComSucesso: 'Gestor alterado com sucesso!',
  messagecadastro: 'Gestor cadastrado com Sucesso!',
  novaconta: 'novo-gestor',
  recuperarsenha: 'recuperar-senha',
  novasenha: 'nova-senha',
  outracontabilidade: 'outro Gestor',
  login: 'login',
  Trial: 'trial',
  meuscontribuintes: 'meus-contribuintes',
  meusgestores: 'gestores',
  adicionarcontribuintes: 'adicionar-contribuintes',
  atualizarcontribuintes: 'atualizar-contribuintes',
  ativarconta: 'ativar-conta',
  meuscertificados: 'meus-certificados',
  adicionarcertificado: 'adicionar-certificado',
  resetsenha: 'reset-senha',
  usuariocontribuinte: 'usuario-contribuinte',
  meusdados: 'meus-dados',
  revisaotributaria: 'revisao-tributaria',
  home: 'home',
  retorno: 'retorno',
  autenticar: 'autenticar',
  financeiro: 'financeiro',
  recuperarconta: 'recuperar-conta',
  comece: 'comece',
  etapa: 'etapa',
  relatorio: 'relatorio-auditoria',

  produtoemitente: 'produto-emitente',
  participanteemitente: 'participante-emitente',
  cenarios: 'cenarios',
  referencial: 'referencial',
  download: 'download',
  consultalivredeprodutos: 'consulta-livre-de-produtos',
  simuladordeoperacoes: 'simulador-de-operacoes',
  confrontarsped: 'confrontar-sped',
  segregacao: 'segregacao',
  verdocumento: 'ver-documento',
  consultainterestadual: 'consulta-interestadual',
  emalote: 'emalote',
  emalotedetails: 'emalote-details',
  visaosituacional: 'visao-situacional',
  ID_Servico_Recuperacao: 17,

  modulointegracao: 'modulo-integracao',
  dashmodulointegracao: 'dash-modulo-integracao',
  SubTituloUpgrade: 'Módulo cancelado ou não contemplado no seu plano',
  TextoUpgrade:
    'Para acessar esse módulo por favor faça upgrade de plano ou reative o seu contrato',
  TextoContratar: 'Para acessar esse módulo, contrate pacotes adicionais',
  cacheBucket: 'imposto-cache',
  AWS_ACCESS_KEY_ID: 'AKIA4HUMI5ERAMATEVJ6',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'sa-east-1',

  tituloModuloAuditoria: 'Auditar Documentos Fiscais Eletrônicos',
  tituloModuloSegregacao: 'Segregação Simples Nacional',
  tituloModuloConsulta: 'Consulta Livre de Impostos de Produtos',
  tituloModuloEmalote: 'e-Malote',
  tituloModuloRevisao: 'Revisar Cadastro de Impostos de Produtos',

  tituloBoxModuloAuditoria: 'Auditoria',
  tituloBoxModuloSegregacao: 'Segregar',
  tituloBoxModuloConsulta: 'Consultas',
  tituloBoxModuloEmalote: 'e-Malote',
  tituloBoxModuloRevisao: 'Revisão',
  tituloBoxModuloRecuperacao: 'Recuperação',

  userImposto: 'userImpostoBR',
  senhaImposto: 'Im@st@10',
  recuperacao: 'recuperacao',

  pluginCaptura: 'plugin-de-captura-ig',

  tipo : {
    55: 'NFEXML',
    57: 'CTEXML',
    67: 'CTEXML',
    65: 'NFCXML',
    66: 'NF3EXML',
    62: 'NFCOMXML',
    59: 'SATXML',
    infEventoNFe: 'NFEEVEXML',
    infChave: 'INFCHAVE',
  },

};
